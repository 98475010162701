import React from 'react';
import parse from "html-react-parser";

function Footer(props) {
  let footerData = props.data;

  const fadeAnimation = () => {
    var elementReveals = document.querySelectorAll(".footerTitle, .footerParagraph");

    for (var i = 0; i < elementReveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = elementReveals[i].getBoundingClientRect().top;
      var elementVisible = 10;

      if (elementTop < windowHeight - elementVisible) {
        elementReveals[i].classList.add("active");
      } else {
        elementReveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", fadeAnimation);


  return (
    <>
      {
        footerData ? (
          footerData[1].data ? (
            <footer className="footer" role="navigation" aria-label="footer navigation" >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 w-100 footerRow position-relative">
                    <div id="footer-background">
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 footerSides">
                  </div>
                  <div className="col-lg-10 footerRow position-relative">
                    <div id="myFooter">
                      {
                        footerData[1].data.footer ? (
                          <div id="footerCopy">
                            {parse(footerData[1].data.footer)}
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                  <div className="col-lg-1 footerSides">
                  </div>
                </div>
              </div>
            </footer>
          ) : (
            null
          )
        ) : (
          null
        )
      }
    </>
  )
}

export default Footer;